<template>
  <div class="user_center">
    <div class="user_center_top">
      <div class="user_center_logo">
        <img
          @error="imageError"
          class="user_center_logo"
          :src="`${$store.state.assetsUrl}/imgs/logo1214.webp`"
          alt="logo"
        />
      </div>
      <div class="user_center_logout" @click="logout">{{ $t("logout") }}</div>
    </div>
    <div class="user_info">
      <div
        class="user_head"
        :style="{ 'background-color': `#${userInfo.avatar_rgb}` }"
      >
        <img
          class="head"
          v-if="headUrl != ''"
          @error="headUrl = ''"
          :src="headUrl"
          alt="user head"
        />
        <span v-else>{{ userFirst }}</span>
      </div>
      <div class="user_email">{{ userInfo.email }}</div>
    </div>
    <div class="manage_subscription" @click="$router.push('/my-subscriptions')">
      <div class="manage_subscription_text">{{ $t("ManageSubscription") }}</div>
      <div class="manage_subscription_icon">></div>
    </div>
    <div class="manage_subscription delete_button" @click="deleteAccount">
      {{ $t("DeleteAccount") }}
    </div>

    <div class="privacy" @click="goLink('privacy')">
      {{ $t("Privacy") }}
    </div>
    <div class="guarantee" @click="goLink('refund')">
      {{ $t("MoneyBack") }}
    </div>

    <van-overlay :show="show">
      <div class="delete_wrap" @click.self="show = false">
        <div class="delete_dialog">
          <div class="delete_title">
            {{ $t("DeleteAccountTitle") }}
          </div>
          <div class="delete_describe">
            {{ $t("DeleteAccountDescribe") }}
          </div>
          <div class="sure_delete_button" @click="goDelete">
            {{ $t("DeleteAccount") }}
          </div>
          <div class="cancel_delete_button" @click="show = false">
            {{ $t("Cancel") }}
          </div>
        </div>
      </div>
    </van-overlay>

    <loading :showCover="isLoading"></loading>
  </div>
</template>

<script>
import { requestInitInfo, deleteAccount } from "@/api/index.js";
import { common } from "../assets/utils/common";
import { commonMixin } from "../assets/mixin/common";
import Loading from "@/components/cover_loading.vue";

export default {
  name: "user-center",
  components: { Loading },
  mixins: [commonMixin],
  data() {
    return {
      userInfo: {},
      initInfo: {},
      headUrl: "",
      show: false,
      isLoading: false,
    };
  },
  created() {
    this.$store.commit("setIsApp", true);
    this.initData();
  },
  methods: {
    initData() {
      // 请求初始化信息
      requestInitInfo().then((res) => {
        if (!res.error) {
          this.initInfo = res;
        }
      });
      let userInfo = this.$changeLocal.getItem("userInfo");
      userInfo && (this.userInfo = userInfo);
    },
    async goDelete() {
      this.isLoading = true;

      deleteAccount()
        .then((res) => {
          this.isLoading = false;
          if (res.error != null) {
            Toast(this.$t("NetworkErrorToast"));
          } else {
            this.logout();
          }
        })
        .catch(() => {
          this.isLoading = false;
          Toast(this.$t("NetworkErrorToast"));
        });
    },
    deleteAccount() {
      this.show = true;
    },
    goLink(text) {
      if (text == "refund") {
        this.$router.push("/refund");
      } else {
        this.$router.push("/privacy/app");
      }
    },
    logout() {
      this.$store.commit("setToken", "");
      this.$changeCookie.clearCookie("loginToken");
      this.$router.push("/");
    },
  },
  watch: {
    initInfo() {
      this.headUrl = common.imgToBase64(
        this.initInfo.image_url,
        this.initInfo.image_bucket,
        this.userInfo.avatar
      );
    },
  },
  computed: {
    userFirst: {
      get() {
        let userName = this.userInfo.user_name;
        if (userName) {
          return userName.substr(0, 1).toUpperCase();
        }
        return "";
      },
      set() {},
    },
  },
};
</script>

<style scoped lang="less">
.delete_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  .delete_dialog {
    max-width: 320px;
    padding: 26px;
    border-radius: 10px;
    background-color: #fff;
    .delete_title {
      font-size: 20px;
      font-family: "AvenirNext";
      font-weight: 600;
      color: #333;
      line-height: 22px;
      margin-bottom: 20px;
    }
    .delete_describe {
      font-size: 16px;
      font-family: "AvenirNext";
      font-weight: 500;
      color: #333;
      line-height: 22px;
      margin-bottom: 20px;
    }

    .sure_delete_button {
      width: 100%;
      height: 40px;
      color: #ee0a24;
      border: 1px solid #ee0a24;
      border-radius: 30px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .cancel_delete_button {
      margin-top: 10px;
      width: 100%;
      height: 40px;
      color: #333;
      border: 1px solid #333;
      border-radius: 30px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
.user_center {
  padding-top: 16px;
  max-width: 765px;
  margin: 0 auto;

  .user_center_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin: 0 18px 0 24px;

    .user_center_logo {
      width: 40px;
      height: 40px;
    }

    .user_center_logout {
      width: 74px;
      height: 28px;
      background: #f3f7f8;
      border-radius: 28px;
      font-size: 12px;
      font-family: "AvenirNext";
      font-weight: 500;
      color: rgba(94, 103, 129, 0.8);
      line-height: 28px;
      cursor: pointer;
    }
  }

  .user_info {
    margin-top: 50px;

    .user_head {
      width: 84px;
      height: 84px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      font-family: "AvenirNext";
      font-weight: 600;
      color: #ffffff;
      overflow: hidden;
      border-radius: 50%;

      .head {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .user_email {
      margin-top: 18px;
      font-size: 18px;
      font-family: "AvenirNext";
      font-weight: 600;
      color: #444444;
      line-height: 18px;
    }
  }

  .manage_subscription {
    display: flex;
    justify-content: space-between;
    margin: 50px 20px 20px;
    height: 52px;
    box-shadow: 0px 0px 10px 0px rgba(151, 151, 151, 0.18);
    background: #ffffff;
    border-radius: 10px;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;

    .manage_subscription_text {
      font-size: 16px;
      font-family: "AvenirNext";
      font-weight: 500;
      color: #333;
      line-height: 22px;
    }

    .manage_subscription_icon {
      color: rgba(51, 51, 51, 0.3);
    }
  }

  .delete_button {
    font-size: 16px;
    font-family: "AvenirNext";
    font-weight: 500;
    color: #333;
    line-height: 22px;
    margin-top: 20px;
  }
  .privacy {
    margin-top: 230px;
  }

  .privacy,
  .guarantee {
    font-size: 14px;
    font-family: "AvenirNext";
    font-weight: 600;
    color: #333333;
    line-height: 19px;
    margin-bottom: 12px;
    text-decoration: underline;
    cursor: pointer;
  }

  img {
    pointer-events: none;
  }
}
</style>
